<template>
  <div class="infeed__ad--container max-w-3xl mr-auto ml-auto">
    <InFeedAdsense
      data-ad-layout-key="-hq+5+1t-ek+me"
      data-ad-client="ca-pub-7702415526221680"
      data-ad-slot="4839874853"
      data-ad-format="fluid"
    >
    </InFeedAdsense>
  </div>
</template>

<script>
export default {
  name: "InfeedAd"
};
</script>
